import React from 'react'
import videosrc from "../../assets/flower.mp4"
const ImmersiveXP = () => {
  return (
    <section id='dmvrtours'>
     <div className='container'>
      <div className='left-side'>
        <div className='title'>
          <h1>For Digital Marketing</h1>
        </div>
        <div className='subtitle'>
          <h3>360 Immersive Experiences</h3>
        </div>
        <div className='description'>
        Immerse your audience with a unique, <br/>one-of-a-kind branding experience!
        </div>
        <div className='bullets'>
          <p className='bullet-1'>
          <span>Stand Out from Competition</span> <br/>
          See You In Space believes in the practical, creative use 
          of 360° Digital Media. Let us work with your business or 
          personal needs to exceed expectations of a truly immersive branding experience!
          </p>
          <p className='bullet-2'>
          <span>Increased Website Traction  </span> <br/>
          Give your audience what they’re looking for on a 
          dynamic new addition to your business’s website! Once 
          they’re there, they’ll notice any action items (shop, 
          contact, etc.) just a click away!
          </p>
        </div>
      </div>
      <div className='right-side'>
      <video src={'https://firebasestorage.googleapis.com/v0/b/seeyouinspace-59437.firebasestorage.app/o/Grand%20Canal%20-%20Venice%2C%20Italy.mp4?alt=media&token=01eb9714-86ef-466c-a18c-90b6d15881d7'} 
          autoPlay loop muted></video>
      </div>
     </div>
    </section>
  )
}

export default ImmersiveXP
