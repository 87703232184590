export const NAVLINKS = [
    {
        id:0,
        name:"For Digital Marketing"
    },
    {
        id:1,
        name:"For Training & Education"
    },
    {
        id:2,
        name:"The App"
    },
    {
        id:3,
        name:"About"
    },
    {
        id:4,
        name:"Contact"
    },
]