/* eslint-disable array-callback-return */
import React from 'react'
import logo from "./logo.png";
import { NAVLINKS } from '../../constants';
import { FaSearch } from 'react-icons/fa';
const Navbar = () => {
  return (
    <header>
        <nav className="navbar">
            <div className="logo">
              <a href='/'>
                <img src={logo} alt='' className='logo'/>
              </a>
            </div>
            <ul className="menu">
              {NAVLINKS.map(item => (
                <li key={item.id}>
                  <a href='/'>
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
            <div className="btn">
                <FaSearch size={20} color='#000000'/>
            </div>
        </nav>
    </header>
  )
}

export default Navbar
