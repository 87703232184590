import React from 'react'
import videosrc from "../../assets/flower.mp4"
const dm_vrtours = () => {
  return (
    <section id='dmvrtours'>
     <div className='container'>
      <div className='left-side'>
        <div className='title'>
          <h1>For Digital Marketing</h1>
        </div>
        <div className='subtitle'>
          <h3>360 Virtual Tours</h3>
        </div>
        <div className='description'>
          Invite the audience of the internet to your place of <br/>
          business virtual tours are proven to 
        </div>
        <div className='bullets'>
          <p className='bullet-1'>
          <span>Increase Visibility</span> <br/>
	        The accessibility of the Web allows business
          owners to invite potential customers to their 
          Venue without having to leave the comforts of 
          home! 
          </p>
          <p className='bullet-2'>
          <span>Time and Cost Savings</span> <br/>
	        Instead of scheduling in-person showings, reduce the 
          time it takes to showcase your Space.
          
          Potential Customers can use Virtual Tours to navigate 
          Spaces, immersing them in an engaging and   
          informational experience that fully sets their expectations 
          for once they physically visit your Space.

          </p>
        </div>
      </div>
      <div className='right-side'>
        <div className='video-container'>
        <video src={'https://firebasestorage.googleapis.com/v0/b/seeyouinspace-59437.firebasestorage.app/o/Jump%20-%20Second%20Scene.mp4?alt=media&token=eccf1638-9efd-4739-ad5a-3a649f8451b6'} 
          autoPlay loop muted></video>
        </div>
      </div>
     </div>
    </section>
  )
}

export default dm_vrtours
