import React from 'react'
import { FaArrowDown } from "react-icons/fa";
const IntroSection = ({videoSrc,children}) => {
  const scrollToSection = () => {
    const nextSection = document.getElementById('dmvrtours');
    if(nextSection){
      const scrollOptions = {
        behavior:"smooth",
        top:nextSection.offsetTop - window.innerHeight / 2
      };
      window.scrollTo(scrollOptions);
    }
  }
  return (
    <div className='wrapper'>
      <section className='video-section'>
        <div className='video-container'>
          <video src={'https://firebasestorage.googleapis.com/v0/b/seeyouinspace-59437.firebasestorage.app/o/Historic%20Waterfall%20-%20Roswell%2C%20Georgia.mp4?alt=media&token=8869c2e0-1eea-4ed6-970a-fe8653052f6b'} 
          autoPlay loop muted></video>
        </div>
        <div className='arrow-container' onClick={scrollToSection()}>
          <a href='#dmvrtours' className='arrow-link'>
            <div className='arrow'>
              <FaArrowDown size={50}/>
            </div>
          </a>
        </div>
        {children}
      </section>
    </div>
  )
}

export default IntroSection
