import { Navbar, Footer } from "./components/common";
import { IntroSection,DmVrTours, ImmersiveXP } from "./components/ui";
import video from './assets/flower.mp4'
function App() {
  return (
    <>
      <Navbar/>
      <IntroSection videoSrc={video}/>
      <DmVrTours/>
      <ImmersiveXP/>
      <Footer/>
    </>
  );
}

export default App;
